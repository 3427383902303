export const FundCategoryList = [
  {
    id: 1,
    name: "EQUITY FUNDS",
    link: "/mutual-funds/category/equity/"
  },
  {
    id: 2,
    name: "TAX FUNDS",
    link: "/mutual-funds/category/tax/"
  },
  {
    id: 3,
    name: "BALANCED FUNDS",
    link: "/mutual-funds/category/balanced/"
  },
  {
    id: 4,
    name: "DEBT FUNDS",
    link: "/mutual-funds/category/debt/"
  },
  {
    id: 5,
    name: "LIQUID FUNDS",
    link: "/mutual-funds/category/liquid/"
  },
  {
    id: 6,
    name: "HYBRID FUNDS",
    link: "/mutual-funds/category/hybrid/"
  }
];

export const FundLinksList = [
  {
    id: 1,
    name: "SBI Mutual Fund",
    link: "/mutual-funds/amc/sbi-mutual-fund/"
  },
  {
    id: 2,
    name: "L&T Mutual Fund",
    link: "/mutual-funds/amc/lnt-investment-mutual-fund/"
  },
  {
    id: 3,
    name: "AXIS Mutual Fund",
    link: "/mutual-funds/amc/axis-asset-mutual-fund/"
  },
  {
    id: 4,
    name: "UTI Mutual Fund",
    link: "/mutual-funds/amc/uti-mutual-fund/"
  },
  {
    id: 5,
    name: "LIC Mutual Fund",
    link: "/mutual-funds/amc/lic-mutual-fund/"
  },
  {
    id: 6,
    name: "TATA Mutual Fund",
    link: "/mutual-funds/amc/tata-mutual-fund/"
  },
  {
    id: 7,
    name: "IDFC Mutual Fund",
    link: "/mutual-funds/amc/idfc-mutual-fund/"
  },
  {
    id: 8,
    name: "PPFAS Mutual Fund",
    link: "/mutual-funds/amc/ppfas-mutual-fund/"
  },
  {
    id: 9,
    name: "IDBI Mutual Fund",
    link: "/mutual-funds/amc/idbi-mutual-fund/"
  },
  {
    id: 10,
    name: "Quantum Mutual Fund",
    link: "/mutual-funds/amc/quantum-mutual-fund/"
  },
  {
    id: 11,
    name: "HDFC Mutual Fund",
    link: "/mutual-funds/amc/hdfc-mutual-fund/"
  },
  {
    id: 12,
    name: "Invesco Mutual Fund",
    link: "/mutual-funds/amc/invesco-mutual-fund/"
  },
  {
    id: 13,
    name: "Union Mutual Fund",
    link: "/mutual-funds/amc/union-kbc-mutual-fund/"
  },
  {
    id: 14,
    name: "HSBC Mutual Fund",
    link: "/mutual-funds/amc/hsbc-mutual-fund/"
  },
  {
    id: 15,
    name: "Kotak Mutual Fund",
    link: "/mutual-funds/amc/kotak-mutual-fund/"
  },
  {
    id: 16,
    name: "Mirae Asset Mutual Fund",
    link: "/mutual-funds/amc/mirae-mutual-fund/"
  },
  {
    id: 17,
    name: "Essel Finance Mutual Fund",
    link: "/mutual-funds/amc/essel-mutual-fund/"
  },
  {
    id: 18,
    name: "Franklin Mutual Fund",
    link: "/mutual-funds/amc/franklin-templeton-mutual-fund/"
  },
  {
    id: 19,
    name: "Nippon India Mutual Fund",
    link: "/mutual-funds/amc/reliance-mutual-fund/"
  },
  {
    id: 20,
    name: "Indiabulls Mutual Fund",
    link: "/mutual-funds/amc/indiabulls-mutual-fund/"
  },
  {
    id: 21,
    name: "BOI AXA Mutual Fund",
    link: "/mutual-funds/amc/boi-axa-mutual-fund/"
  },
  {
    id: 22,
    name: "Principal Mutual Fund",
    link: "/mutual-funds/amc/principal-mutual-fund/"
  },
  {
    id: 23,
    name: "Sundaram Mutual Fund",
    link: "/mutual-funds/amc/sundaram-mutual-fund/"
  },
  {
    id: 24,
    name: "Mahindra Mutual Fund",
    link: "/mutual-funds/amc/mahindra-mutual-fund/"
  },
  {
    id: 25,
    name: "Edelwiess Finance Mutual Fund",
    link: "/mutual-funds/amc/edelweiss-mutual-fund/"
  },
  {
    id: 26,
    name: "BNP Paribas Mutual Fund",
    link: "/mutual-funds/amc/bnp-paribas-mutual-fund/"
  },
  {
    id: 27,
    name: "Motilal Oswal Mutual Fund",
    link: "/mutual-funds/amc/motilal-oswal-mutual-fund/"
  },
  {
    id: 28,
    name: "PGIM India Mutual Fund",
    link: "/mutual-funds/amc/pgim-india-mutual-fund/"
  },
  {
    id: 29,
    name: "Baroda Pioneer Mutual Fund",
    link: "/mutual-funds/amc/baroda-pioneer-mutual-fund/"
  },
  {
    id: 30,
    name: "DSP Blackrock Mutual Fund",
    link: "/mutual-funds/amc/dsp-blackrock-mutual-fund/"
  },
  {
    id: 31,
    name: "ICICI Prudential Mutual Fund",
    link: "/mutual-funds/amc/icici-prudential-mutual-fund/"
  },
  {
    id: 32,
    name: "Canera Robeco Mutual Fund",
    link: "/mutual-funds/amc/canara-robeco-mutual-fund/"
  },
  {
    id: 33,
    name: "Aditya Birla Sun Life Finance Mutual Fund",
    link: "/mutual-funds/amc/birla-sun-life-mutual-fund/"
  }
];

export const SITE_NAV_DATA = [
  {
    heading: "RESOURCES",
    data: [
      {
        name: "Blog",
        link: "https://www.piggy.co.in/blog/",
        type: "external"
      },
      {
        name: "Mutual Fund Explorer",
        link: "https://www.piggy.co.in/best-mutual-funds/",
        type: "external"
      },
      {
        name: "Mutual Funds AMCs",
        link: "/mutual-funds/amc/",
        type: "internal"
      },
      {
        name: "Calculators",
        link: "/calculators",
        type: "internal"
      },
      {
        name: "Piggy Premier",
        link: "/premier",
        type: "internal"
      },
      {
        name: "Mutual Funds Sahi Hai",
        link: "/mutual-funds-sahi-hai/",
        type: "internal"
      }
    ]
  },
  {
    heading: "WHO WE ARE",
    data: [
      {
        name: "About Us",
        link: "/about/",
        type: "internal"
      },
      { name: "FAQ",
        link: "/faq/",
        type: "internal" },
      {
        name: "Submit a query",
        link: "https://piggy.zendesk.com/hc/en-us/requests/new",
        type: "external"
      },
      {
        name: "Careers",
        link: "https://angel.co/piggy-2/jobs",
        type: "external"
      },
      { name: "Contact Us",
        link: "/contact/",
        type: "internal"
      }
    ]
  },
  {
    heading: "LEGAL",
    data: [
      {
        name: "Terms and Conditions",
        link: "/legal/",
        type: "internal"
      },
      {
        name: "Privacy Policy",
        link: "/privacy/",
        type: "internal"
      },
      {
        name: "Risk",
        link: "/risk/",
        type: "internal"
      },
      {
        name: "Disclaimer",
        link: "/disclaimer/",
        type: "internal"
      },
      {
        name: "Investor Charter",
        link: "/investor-charter/",
        type: "internal"
      }
    ]
  }
];
