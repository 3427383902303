import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../styles/global.css";
import { setUtmParams } from "../../utilities";
import { Location } from "@reach/router";

function Layout(props) {
  return (
    <Location>
      {({ location }) => {
        setUtmParams(location.href);
        return (
          <>
            <Header path={location.pathname} />
            {props.children}
            <Footer />
          </>
        );
      }}
    </Location>
  );
}

export default Layout;
