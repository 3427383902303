import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { MdPieChartOutlined } from "react-icons/md";
import { isLoggedIn, sendToUrl } from "../../utilities";
import headerStyles from "./header.module.css";
import directMf from "../../images/direct-mf.svg";
import digitalGold from "../../images/digital-gold.svg";
import epf from "../../images/epf.svg";
import premier from "../../images/advisory.svg";
import simplySave from "../../images/simply-save.svg";
import sip from "../../images/sip.svg";
import taxSaving from "../../images/tax-saving.svg";
import { FaApple, FaGooglePlay, FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";
import OutsideClickFinder from "../OutsideClickFinder";
/*

* Constants 

*/

const NAV_LINKS = [
  { name: "Explore Funds", link: "/search" },
  { name: "Top Funds", link: "/top-mutual-funds" },
  { name: "Articles", link: "https://piggy.co.in/blog" },
  { name: "FAQ", link: "/faq" },
  { name: "Calculators", link: "/calculators" }
];

const PRODUCTS = [
  {
    name: "Direct Mutual Funds",
    link: "https://www.piggy.co.in/search/",
    id: "mutual-fund",
    icon: directMf
  },
  {
    name: "Digital Gold",
    link: "https://www.piggy.co.in/gold/",
    id: "gold",
    icon: digitalGold
  },
  {
    name: "Track EPF",
    link: "https://www.piggy.co.in/epf/",
    id: "epf",
    icon: epf
  }
];

const FEATURES = [
  {
    name: "Simply Save",
    link: "https://www.piggy.co.in/simply-save/",
    id: "simply-save",
    icon: simplySave
  },
  {
    name: "Tax Saving",
    link: "/elss/",
    id: "elss",
    icon: taxSaving
  },
  {
    name: "Start SIP",
    link: "https://www.piggy.co.in/top-mutual-funds/",
    id: "sip",
    icon: sip
  }
];

const SOLUTIONS = [
  {
    name: "Piggy Premier",
    link: "/premier/",
    id: "premier",
    icon: premier
  }
];

/*

* React FC

*/

const Header = ({ path }) => {
  const [cookies] = useCookies();
  const userLoggedIn = isLoggedIn(cookies.token || "");
  const [isMobile, setIsMobile] = useState(false);
  const [isDropDownVisible, toggleDropDownVisibility] = useState(false);
  useEffect(() => {
    if (window) {
      if (window.innerWidth <= 968) setIsMobile(true);
      else setIsMobile(false);
    }
  }, []);

  const renderProducts = product => {
    switch (product.id) {
      case "gold":
      case "mutual-fund":
      case "simply-save":
      case "sip":
      case "epf":
        return (
          <a href={product.link}>
            <div className={headerStyles.productBox}>
              <img src={product.icon} className={headerStyles.productIcon} />
              <p className="para-2-lite text-grey-1 align-self">
                {product.name}
              </p>
            </div>
          </a>
        );
      case "premier":
      case "elss":
        return (
          <Link to={product.link}>
            <div className={headerStyles.productBox}>
              <img src={product.icon} className={headerStyles.productIcon} />
              <p className="para-2-lite text-grey-1 align-self">
                {product.name}
              </p>
            </div>
          </Link>
        );
      default:
        console.log("Invalid Product");
    }
  };

  return (
    <header className={headerStyles.container}>
      <div className="d-f s-b f-h a-i-c">
        <div className={headerStyles.piggyLogo}>
          <img
            src={"https://assets.piggy.co.in/piggy-logo-black-text.svg"}
            alt="Piggy Logo"
            style={{width: "125px" }}
          />

          {path === "/wealth/" && !isMobile && (
            <OutsideClickFinder
              onOutsideClick={() =>  toggleDropDownVisibility(false)}
              styles={{ alignSelf: "center" }}
            >
              <div className={`d-f ${headerStyles.position_relative}`}>
                <div
                  className="d-f c-p"
                  onClick={() => toggleDropDownVisibility(!isDropDownVisible)}
                >
                  <p className={headerStyles.productsText}>Products</p>
                  <FaAngleDown
                    style={{ alignSelf: "center", margin: "auto 10px" }}
                  />
                </div>
                {isDropDownVisible && (
                  <div className={headerStyles.productContainer}>
                    <ul style={{ margin: "24px 0 16px 16px" }}>
                      <p className="para-3-bold">Products</p>
                      {PRODUCTS.map((product, i) => {
                        return <li>{renderProducts(product)} </li>;
                      })}
                    </ul>
                    <div className={headerStyles.hr} />
                    <ul style={{ margin: "16px 0 16px 16px" }}>
                      <p className="para-3-bold"> Features</p>
                      {FEATURES.map(feature => {
                        return <li> {renderProducts(feature)}</li>;
                      })}
                    </ul>
                    <div className={headerStyles.hr} />
                    <Link to={SOLUTIONS[0].link}>
                      <div
                        className={`d-f ${headerStyles.productBox}`}
                        style={{ margin: "16px 0 16px 16px" }}
                      >
                        <img
                          src={SOLUTIONS[0].icon}
                          className={headerStyles.productIcon}
                        />
                        <div>
                          <p className="para-3-bold">Solutions</p>
                          <p className="para-2-lite text-grey-1 align-self">
                            {SOLUTIONS[0].name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                <FaGooglePlay style={{ margin: "auto 10px" }} />
                <FaApple style={{ margin: "auto 10px" }} />
              </div>
            </OutsideClickFinder>
          )}
        </div>

        {path !== "/wealth/" && (
          <div className="nav_links">
            {NAV_LINKS.map((nav, id) => (
              <a
                key={id}
                href={sendToUrl(nav.link)}
                className={headerStyles.navLinks}
              >
                {nav.name}
              </a>
            ))}
          </div>
        )}
        <div className={headerStyles.actionContainer}>
          {userLoggedIn ? (
            <a
              href="https://piggy.co.in/dashboard/"
              className={headerStyles.dashboardLink}
            >
              <MdPieChartOutlined className={headerStyles.dashboardIcon} />
              <span>Dashboard</span>
            </a>
          ) : (
            <>
              {/* <img src={ic_sign_up} alt="piggy sign up" /> */}
              <a
                href={sendToUrl("/login/")}
                className={`${headerStyles.nav_actions_link} ${headerStyles.nav_action_hide}`}
              >
                <p className={headerStyles.loginText}></p>
                Login
              </a>
              {/* <span
                className={`${headerStyles.nav_actions_link} ${headerStyles.nav_action_hide}`}
              >
                |
              </span> */}
              <a
                href={sendToUrl("/signup/")}
                className={`${headerStyles.nav_actions_link} 
            }`}
              >
                <button className={headerStyles.getStartedButton}>
                  Get Started
                </button>
              </a>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
