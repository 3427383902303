import queryString from "query-string";
import { Cookies } from "react-cookie";

export function getRoundedInrFormatWithSymbol(number) {
  const isNegative = number < 0;
  number = Math.round(number).toString();
  number = isNegative ? number.substr(1) : number;

  let lastThree = number.substring(number.length - 3);
  const otherNumbers = number.substring(0, number.length - 3);

  if (otherNumbers != "") lastThree = `,${lastThree}`;

  let formatted =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  formatted = isNegative ? `-${formatted}` : formatted;

  return `₹${formatted}`;
}

export function getRoundedInrFormat(number) {
  const isNegative = number < 0;
  number = Math.round(number).toString();
  number = isNegative ? number.substr(1) : number;

  let lastThree = number.substring(number.length - 3);
  const otherNumbers = number.substring(0, number.length - 3);

  if (otherNumbers != "") lastThree = `,${lastThree}`;

  let formatted =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  formatted = isNegative ? `-${formatted}` : formatted;

  return formatted;
}

export function getAmc(amcName) {
  return amcName.split(" ")[0];
}

// Is Logged In

export const DEFAULT_TOKEN = "d1d2c9d9375d96f49b40b9c6510436b576c0d68d";

export const isLoggedIn = token => {
  return !!token && token !== DEFAULT_TOKEN;
};

export const getAuthCookie = () => {
  let b =
    typeof window !== `undefined`
      ? window.document.cookie.match(
        "(^|[^;]+)\\s*" + "token" + "\\s*=\\s*([^;]+)"
      )
      : "";

  let token = b ? b.pop() : "";
  return token;
};

export const loadFromCookie = str => {
  let b =
    typeof window !== `undefined`
      ? window.document.cookie.match("(^|[^;]+)\\s*" + str + "\\s*=\\s*([^;]+)")
      : "";

  let token = b ? b.pop() : "";
  return token;
};

export function sendToUrl(url) {
  const tokenCookie = getAuthCookie();
  const utmCookie = loadFromCookie("utm_params");
  const isCamp = loadFromCookie("is_camp");
  if (isCamp === "campaign" && utmCookie) {
    url =
      url.indexOf("?") !== -1 ? `${url}&${utmCookie}` : `${url}?${utmCookie}`;
  }
  return url;
}

export function setUtmParams(path) {
  let utmParams = "";
  const queryParams = path ? path.split("?")[1] : null;
  const values = queryString.parse(queryParams);
  delete values.q;

  const keys = Object.keys(values);
  for (let i = 0; i < keys.length; i++) {
    if (
      keys[i].indexOf("utm_") !== -1 ||
      keys[i] === "ps" ||
      keys[i] === "r" ||
      keys[i].indexOf("adgroup") !== -1
    ) {
      utmParams += values[keys[i]] ? `${keys[i]}=${values[keys[i]]}` : keys[i];
      if (i < keys.length - 1) utmParams += "&";
    }
  }

  if (utmParams && utmParams.indexOf("utm_source") !== -1) {
    setToken("utm_params", utmParams);
    setToken("is_camp", "campaign");
  } else {
    setToken("is_camp", "nocampaign");
  }
}

export function setToken(tokenName, value) {
  const cookie = new Cookies();
  cookie.remove(tokenName);
  cookie.set(tokenName, value, {
    path: "/",
    maxAge: 2592000,
    sameSite: "lax"
  });
}

export function toTitleCase(str) {
  if (str !== undefined) {
    return str
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
  }
  return str;
}

export function getUrlSegment(str) {
  return str ? str.toLowerCase().replace(/ /g, "-") : "";
}

/* Implementation of lodash.get function */
export const get = (obj, path, defaultValue) => {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce(
      (res, key) => (res !== null && res !== undefined ? res[key] : res),
      obj
    );
  return result === undefined || result === obj ? defaultValue : result;
};
export function makeChunks(array, chunkSize) {
  let i;
  const j = array.length;

  const chunks = [];
  for (i = 0; i < j; i += chunkSize) {
    const temp = array.slice(i, i + chunkSize);
    chunks.push(temp);
  }
  return chunks;
}
