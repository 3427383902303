import React from "react";
import { Link } from "gatsby";
import footerStyles from "./footer.module.css";
import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram } from "react-icons/fa";
import "../../global.css";
import { makeChunks, sendToUrl } from "../../utilities";
import { FundCategoryList, FundLinksList, SITE_NAV_DATA } from "./data.js";

export default function Footer() {
  return (
    <div className={footerStyles.footerWrapper}>
      <div className={footerStyles.content}>
        <div className="d-f s-b a-i-c">
          <span className={footerStyles.getAppText}>
            Get the mobile app on your iOS/Android Device
          </span>
          <div className="d-f">
            <a href="https://apps.apple.com/us/app/piggy-all-mutual-funds-in-one/id1167413844">
              <img
                src="https://assets.piggy.co.in/AppStoreLight.png"
                alt="Apple store"
                className={footerStyles.downlaodIcon}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.valuevest&referrer=utm_source%3Dwebsite%26utm_medium%3Dwebsite%26utm_term%3Ddownload_app%26utm_content%3Dwebsite_footer">
              <img
                src="https://assets.piggy.co.in/PlayStoreLight.png"
                alt="Play store"
                className={footerStyles.downlaodIcon}
              />
            </a>
          </div>
        </div>
        <hr className={footerStyles.divider} />
        <MfLinks />
        <hr className={footerStyles.divider} />
        <SiteMap />
        <hr className={footerStyles.divider} />
        <DisclaimerSection />
        <hr className={footerStyles.divider} />
        <ComplaintTable />
        <hr className={footerStyles.divider} />
        <Disclaimer />
      </div>
    </div>
  );
}

const ComplaintTable = () => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date();
  var month = date.getMonth() - 1;
  var year = date.getFullYear();
  if (month == -1) {
    month = 11;
    year = year - 1;
  }

  return (
    <div className={footerStyles.tableContainer}>
      <div className={footerStyles.tableHeading}>
        As per SEBI guidelines all RIA’s are required to disclose complaints on
        their website. Following the same, the details are mentioned below:
      </div>
      <table className={footerStyles.table} border="1">
        <caption style={{ margin: "20px 0" }}>
          Number of Complaints - {months[month]} - {year}
        </caption>
        <tr>
          <th className={footerStyles.tableCell}>
            At the Beginning of the month
          </th>
          <th className={footerStyles.tableCell}>Received during the Month</th>
          <th className={footerStyles.tableCell}>Resolved during the month</th>
          <th className={footerStyles.tableCell}>
            Pending at the end of the month
          </th>
          <th className={footerStyles.tableCell}>Reason for pendency</th>
        </tr>
        <tr className={footerStyles.tableRow}>
          <td className={footerStyles.tableCell}>0</td>
          <td className={footerStyles.tableCell}>0</td>
          <td className={footerStyles.tableCell}>0</td>
          <td className={footerStyles.tableCell}>0</td>
          <td className={footerStyles.tableCell}>0</td>
        </tr>
      </table>
    </div>
  );
};

const DisclaimerSection = () => {
  return (
    <div>
      <div className={footerStyles.disclaimer_head}>DISCLAIMER</div>
      <p className={footerStyles.disclamier}>
        Valuevest Services Private Limited is a Registered Investment Advisor
        having SEBI Registration no: INA000011343. Correspondence office: Piggy
        HQ, 3rd Floor, The Atlantic Centre, 2007, HAL II Stage, 100 Ft Road
        Indiranagar, Bangalore - 560038. For any issues pertaining to
        transactions reach us at support@piggy.co.in and mention all the order
        details. Procedure to file a complaint on SEBI SCORES: Register on the
        SCORES portal. Mandatory details for filing complaints on SCORES: Name,
        PAN, Address, Mobile Number, E-mail ID. Benefits: Effective
        Communication, Speedy redressal of the grievances. Prevent unauthorized
        transactions in your account. Update your mobile numbers/email IDs with
        your platform. Issued in the interest of investors. KYC is a one-time
        exercise while dealing in securities markets-once KYC is done through a
        SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need
        not undergo the same process again when you approach another
        intermediary Valuevest Services Private Limited makes no warranties or
        representations, express or implied, on products offered through the
        platform. It accepts no liability for any damages or losses, however
        caused, in connection with the use of, or on the reliance of its product
        or related services. Unless otherwise specified, all returns, expense
        ratio, NAV, etc are historical and for illustrative purposes only.
        Future will vary greatly and depends on personal and market
        circumstances. The information provided by our blog is educational only
        and is not investment or tax advice. Mutual fund investments are subject
        to market risks. Please read all scheme related documents carefully
        before investing. Past performance of the schemes is neither an
        indicator nor a guarantee of future performance. Terms and conditions of
        the website/app are applicable. Privacy policy of the website is
        applicable.
      </p>
    </div>
  );
};
const Disclaimer = () => (
  <div className="d-f j-c-c">
    <span className={footerStyles.disclaimer}>
      Mutual Funds investments are subject to market risks, read all scheme
      related documents carefully.
    </span>
  </div>
);

const amcChunks = makeChunks(FundLinksList, 9);

const MfLinks = () => {
  return (
    <div className="d-f s-b">
      <section className="container footer-fund-info">
        <section className="footer-fund-category">
          <h3>FUND CATEGORY</h3>
          <ul className="footer-fund-category-list">
            {FundCategoryList.map((data, id) => (
              <li className="footer-fund-category-list-item">
                <Link to={data.link}>{data.name}</Link>
              </li>
            ))}
          </ul>
        </section>
      </section>

      <section className="footer-fund-houses">
        <h3>MUTUAL FUND HOUSE</h3>
        <div style={{ display: "flex" }}>
          {amcChunks.map((chunk, ind) => (
            <div
              key={ind}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 25,
              }}
            >
              {chunk.map((item) => (
                <Link className="links" to={sendToUrl(item.link)} key={item.id}>
                  <div className="name">{item.name}</div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </section>
      <style jsx>
        {`
          .links {
            text-decoration: none;
          }
          .name {
            color: #999999;
            margin-top: 20px;
            font-size: 12px;
          }
          ".name:hover : {
            color: #fff;
            cursor: pointer;
          }

        `}
      </style>
    </div>
  );
};

const SiteMap = () => {
  return (
    <div className="d-f s-b">
      <div className="d-f-c s-b">
        <img
          src="https://assets.piggy.co.in/piggy-logo-white-text.svg"
          alt="Piggy Logo"
          className={footerStyles.footerLogo}
        />
        <div className="d-f-c">
          <span className={footerStyles.footerPiggyName}>
            © Valuevest.in (Piggy)
          </span>
          <span className={footerStyles.copyright}>All Rights Reserved</span>
          <div className="d-f s-b" style={{ marginTop: "24px" }}>
            <a href="https://twitter.com/savewithpiggy/">
              <FaTwitter className={footerStyles.socialIcons} />
            </a>
            <a href="https://www.facebook.com/savewithpiggy">
              <FaFacebook className={footerStyles.socialIcons} />
            </a>
            <a href="https://www.instagram.com/savewithpiggy/">
              <FaInstagram className={footerStyles.socialIcons} />
            </a>
            <a href="https://www.linkedin.com/company/savewithpiggy/">
              <FaLinkedin className={footerStyles.socialIcons} />
            </a>
          </div>
        </div>
      </div>

      <div className="d-f s-b" style={{ flex: "0.8" }}>
        {SITE_NAV_DATA.map((col, index) => (
          <div className="d-f-c" key={index}>
            <span className={footerStyles.linkHeading}>{col.heading}</span>
            {col.data.map((link, id) => (
              <div style={{ marginTop: "10px" }} key={id}>
                {link.type === "external" ? (
                  <a target={"_blank"} href={sendToUrl(link.link)}>
                    <div className={footerStyles.links}>{link.name}</div>
                  </a>
                ) : (
                  <Link
                    to={sendToUrl(link.link)}
                    className={footerStyles.links}
                  >
                    {link.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
